@font-face {
    font-family: 'GothamPro';
    src: url('./fonts/GothamPro-Medium.woff2') format('woff2'),
        url('./fonts/GothamPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GothamPro';
    src: url('./fonts/GothamPro-Bold.woff2') format('woff2'),
        url('./fonts/GothamPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GothamPro';
    src: url('./fonts/GothamPro.woff2') format('woff2'),
        url('./fonts/GothamPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

